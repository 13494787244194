import { useContext, useMemo } from "react";
import { matchRoutes, useLocation } from "react-router";
import "./styles.scss";
import clsx from "clsx";

import { useAuth } from "contexts/auth/useAuth";
import ThemeContext from "contexts/theme/ThemeContext";
import { useMediaQuery } from "react-responsive";
import permissions_utils from "utils/permissions_utils";

import {
  faAnglesLeft,
  faAnglesRight,
  faArrowLeft,
  faLaptop
} from "@fortawesome/free-solid-svg-icons";
import SidebarCell from "./SidebarCell";

import mainRoutes from "navigation/Main.routes";
import settingsRoutes from "navigation/Settings.routes";
import { useTranslation } from "react-i18next";
import EntitySelector from "components/EntitySelector";
import url_utils from "utils/url_utils";
import AppRouteObject from "models/app/AppRouteObject";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const { sidebarExpanded, toggleExpandSidebar } = useContext(ThemeContext);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const showSidebarExpanded = isMobile || sidebarExpanded;

  const auth = useAuth();
  const location = useLocation();
  const { t } = useTranslation();
  const match = matchRoutes(mainRoutes, location);
  const isMainRoutes = match !== null;

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const canShowRoute = (route: AppRouteObject): boolean => {
    const permissions = route.meta?.permissions;
    const requiresCompany = route.meta?.requiresCompany;
    const permissionBlock = permissions
      ?.map((permission) => !permissions_utils.can(permission, auth))
      .some(Boolean);

    const companyBlock = requiresCompany && !permissions_utils.hasCompany(auth);

    return !permissionBlock && !companyBlock;
  };

  const goBackUrl = useMemo(() => {
    if (can("view dashboard")) {
      return url_utils.getDashboardUrl() + "/overview";
    } else {
      return "/index";
    }
  }, []);

  return (
    <aside
      className={clsx(
        "sidebar",
        !showSidebarExpanded ? "sidebar-reduced" : null
      )}
    >
      <div className="sidebar-content">
        <EntitySelector />
        <ul className="sidebar-list">
          {(isMainRoutes ? mainRoutes : settingsRoutes).map((route) => {
            const isCanShowRoute = canShowRoute(route);

            if (isCanShowRoute) {
              return (
                <SidebarCell
                  key={route.meta!.title}
                  addSeparator={route.meta?.addSeparator}
                  icon={route.meta!.icon!}
                  color={route.meta!.color}
                  isExternal={route.meta?.external}
                  title={t(route.meta!.title!)}
                  expanded={showSidebarExpanded}
                  to={route.path}
                />
              );
            }
            return null;
          })}
        </ul>
      </div>
      <div className="sidebar-bottom-buttons-container">
        {!isMainRoutes && (
          <NavLink
            to={goBackUrl}
            target="_self"
            style={{ textDecoration: "none" }}
            reloadDocument={can("view app") ? false : true}
          >
            <SidebarCell
              icon={showSidebarExpanded ? faArrowLeft : faLaptop}
              title={
                can("view app")
                  ? t("pages.back_to_app")
                  : t("pages.back_to_dashboard")
              }
              expanded={showSidebarExpanded}
            />
          </NavLink>
        )}
        <div className="sidebar-expand-button" onClick={toggleExpandSidebar}>
          <SidebarCell
            icon={showSidebarExpanded ? faAnglesLeft : faAnglesRight}
            title={t("pages.collapse")}
            expanded={showSidebarExpanded}
          />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
