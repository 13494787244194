import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReportAnonymously from "components/ReportAnonymously";
import ReportBanner from "components/ReportBanner";
import ReportChat from "components/ReportChat";
import ReportEvidence from "components/ReportEvidence";
import ReportHeader from "components/ReportHeader";
import ReportNotes from "components/ReportNotes";
import ReportQuestionnaire from "components/ReportQuestionnaire";
import ReportInternalStatus from "models/app/ReportInternalStatus";
import QuestionStep from "models/QuestionStep";
import { ReportInfo } from "models/ReportInfo";
import { Dispatch, SetStateAction } from "react";
import permissions_utils from "utils/permissions_utils";
import ReportSubmittedMobile from "../ReportSubmittedMobile";
import ReportSubmittedSidebar from "../ReportSubmittedSidebar";
import { useAuth } from "contexts/auth/useAuth";

const ReportContent: React.FC<{
  report: ReportInfo | null;
  steps: QuestionStep[];
  isReportSubmittable: boolean;
  currentStatus: ReportInternalStatus | null;
  isAnonymously: boolean | null;
  setIsAnonymously: Dispatch<SetStateAction<boolean | null>>;
  isFirebaseAuth: boolean;
}> = ({
  report,
  steps,
  isReportSubmittable,
  currentStatus,
  isAnonymously,
  setIsAnonymously,
  isFirebaseAuth
}) => {
  const auth = useAuth();

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const reporterIsUser = () => {
    return auth?.user?.role === "user";
  };

  if (report && steps.length > 0) {
    if (isReportSubmittable) {
      return (
        <div className="report-content">
          <ReportBanner status={currentStatus} />
          <div className="row report-row">
            <div className="col-sm-6 report-col">
              <ReportHeader report={report} />
            </div>
            {reporterIsUser() && (
              <div className="col-sm-6 report-col">
                <ReportAnonymously
                  report={report}
                  isAnonymously={isAnonymously}
                  setIsAnonymously={setIsAnonymously}
                />
              </div>
            )}
          </div>
          <div className="row report-row">
            <div className="col-sm-6 report-col">
              <ReportQuestionnaire
                report={report}
                steps={steps}
                status={currentStatus}
              />
            </div>
            <div className="col-sm-6 report-col">
              <ReportEvidence report={report} status={currentStatus} />
            </div>
          </div>
          {/* {can("SUPER ADMIN - view company report") ? (
            <ReportNotes report={report} />
          ) : null} */}
        </div>
      );
    } else {
      return (
        <>
          <div className="report-submitted">
            <div className="row">
              <div className="col-sm-8 report-col">
                <ReportChat report={report} isFirebaseAuth={isFirebaseAuth} />
              </div>
              <div className="col-sm-4 report-col">
                <ReportSubmittedSidebar
                  report={report}
                  currentStatus={currentStatus}
                  steps={steps}
                />
              </div>
            </div>
          </div>
          <ReportSubmittedMobile
            report={report}
            currentStatus={currentStatus}
            steps={steps}
            isFirebaseAuth={isFirebaseAuth}
          />
        </>
      );
    }
  }
  return (
    <div className="report-loading">
      <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
    </div>
  );
};

export default ReportContent;
