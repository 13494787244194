import api from "./api";
import constants from "./constants";

const getIntroReport = () => {
  return api.request_get_auth(constants.INTRO_URL);
};

const getIntroReportOLD = () => {
  return api.request_get_auth(constants.OLD_INTRO_URL);
};

const getSteps = (report_id) => {
  return api.request_get_auth(
    `${constants.REPORT_URL}${report_id}/${constants.STEPS_URL}`
  );
};

const getReport = (id) => {
  return api.request_get_auth(`${constants.REPORT_URL}${id}`);
};

const postReportStep = (id, step, answers) => {
  return api.request_post_auth(`${constants.REPORT_URL}${id}/step/${step}`, {
    questions: answers
  });
};

const postReport = () => {
  return api.request_post_auth(constants.REPORT_URL, {});
};

const updateReport = (id, params) => {
  return api.request_put_auth(`${constants.REPORT_URL}${id}`, params);
};

const getFirebaseToken = (id) => {
  return api.request_get_auth(`${constants.REPORT_URL}${id}/token`);
};

const uploadEvidence = (id, files, onUploadProgress) => {
  return api.request_post_multipart_auth(
    `${constants.REPORT_URL}${id}/upload`,
    files,
    onUploadProgress
  );
};

const deleteReport = (id) => {
  return api.request_delete_auth(`${constants.REPORT_URL}${id}`);
};

const reportApi = {
  getIntroReport,
  getIntroReportOLD,
  getSteps,
  getReport,
  postReportStep,
  postReport,
  updateReport,
  getFirebaseToken,
  uploadEvidence,
  deleteReport
};

export default reportApi;
