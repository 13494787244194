import AppRouteObject from "models/app/AppRouteObject";
import RequireAuth from "contexts/auth/RequireAuth";
import {
  faBullhorn,
  faChartBar,
  faChartLine,
  faClipboard,
  faComments,
  faGavel,
  faHandshakeSimple,
  faHome,
  faMap,
  faMapPin,
  faNewspaper,
  faUsers
} from "@fortawesome/free-solid-svg-icons";

import Main from "pages/MainPage";
import Newsfeed from "pages/NewsfeedPage";
import Report from "pages/ReportPage";
import ReportStep from "pages/ReportStepPage";
import NewsfeedPost from "pages/NewsfeedPostPage";
import PostsPage from "pages/PostsPage";
import PostPage from "pages/PostPage";
import JustSayin from "pages/JustSayin";
import JustSayinCreate from "pages/JustSayinCreate";
import JustSayinDetail from "pages/JustSayinDetail";

let mainRoutes: AppRouteObject[] = [
  {
    path: "index",
    meta: {
      title: "pages.overview",
      icon: faHome,
      color: "dark_red",
      permissions: [
        "view dashboard",
        "view dashboard overview",
        "generate company analytics"
      ],
      external: true,
      addSeparator: true
    }
  },
  {
    path: "reports",
    meta: {
      title: "pages.reports",
      icon: faClipboard,
      color: "red",
      permissions: ["view dashboard", "view dashboard overview"],
      external: true
    }
  },
  {
    path: "reporters",
    meta: {
      title: "pages.reporters",
      icon: faGavel,
      color: "dark_blue",
      permissions: ["view dashboard", "view dashboard overview"],
      external: true
    }
  },
  {
    path: "accused",
    meta: {
      title: "pages.accused",
      icon: faUsers,
      color: "light_blue",
      permissions: ["view dashboard", "view dashboard overview"],
      external: true
    }
  },
  {
    path: "locations",
    meta: {
      title: "pages.locations",
      icon: faMap,
      color: "green",
      permissions: ["view dashboard", "view dashboard overview"],
      external: true
    }
  },
  {
    path: "places",
    meta: {
      title: "pages.places",
      icon: faMapPin,
      color: "dark_green",
      permissions: ["view dashboard", "view dashboard overview"],
      external: true
    }
  },
  {
    path: "behaviors",
    meta: {
      title: "pages.behaviors",
      icon: faComments,
      color: "blue",
      permissions: ["view dashboard", "view dashboard overview"],
      external: true
    }
  },
  {
    path: "analytics",
    meta: {
      title: "pages.analytics",
      icon: faChartLine,
      color: "light_red",
      permissions: ["view dashboard", "generate company analytics"],
      external: true,
      addSeparator: true
    }
  },
  {
    path: "reports",
    meta: {
      title: "pages.walk_in_reports",
      icon: faHandshakeSimple,
      color: "blue",
      permissions: ["view dashboard", "create third-party report"]
    },
    children: [
      {
        path: "",
        element: (
          <RequireAuth
            component={Main}
            permissions={["create third-party report"]}
          />
        )
      },
      {
        path: "report/:reportId",
        element: (
          <RequireAuth
            component={Report}
            permissions={["create third-party report"]}
          />
        )
      },
      {
        path: "report/:reportId/step/:stepId",
        element: (
          <RequireAuth
            component={ReportStep}
            permissions={["create third-party report"]}
          />
        )
      }
    ]
  },
  {
    path: "index",
    meta: {
      title: "pages.my_reports",
      icon: faClipboard,
      color: "blue",
      permissions: ["view app"]
    },
    children: [
      {
        path: "",
        element: <RequireAuth component={Main} permissions={["view app"]} />
      },
      {
        path: "report/:reportId",
        element: <RequireAuth component={Report} permissions={["view app"]} />
      },
      {
        path: "report/:reportId/step/:stepId",
        element: (
          <RequireAuth component={ReportStep} permissions={["view app"]} />
        )
      }
    ]
  },
  {
    path: "feed",
    meta: {
      title: "pages.feed",
      icon: faNewspaper,
      color: "yellow",
      permissions: ["read feed"]
    },
    children: [
      {
        path: "",
        element: (
          <RequireAuth component={Newsfeed} permissions={["read feed"]} />
        )
      },
      {
        path: "posts/:postId/new",
        element: (
          <RequireAuth component={NewsfeedPost} permissions={["create post"]} />
        )
      },
      {
        path: "posts/:postId/edit",
        element: (
          <RequireAuth component={NewsfeedPost} permissions={["update post"]} />
        )
      },
      {
        path: "posts/:postId",
        element: (
          <RequireAuth component={PostPage} permissions={["read post"]} />
        )
      },
      {
        path: "posts/list",
        element: (
          <RequireAuth component={PostsPage} permissions={["read feed"]} />
        )
      }
    ]
  }
];

if (process.env.REACT_APP_JUSTSAYIN_ENABLED === "true") {
  mainRoutes.push({
    path: "just-sayin",
    meta: {
      title: "pages.just_sayin",
      icon: faBullhorn,
      color: "orange",
      permissions: ["read voice"],
      requiresCompany: true
    },
    children: [
      {
        path: "",
        element: (
          <RequireAuth
            component={JustSayin}
            permissions={["read voice"]}
            requiresCompany
          />
        )
      },
      {
        path: "new",
        element: (
          <RequireAuth
            component={JustSayinCreate}
            permissions={["create voice"]}
            requiresCompany
          />
        )
      },
      {
        path: ":voiceId",
        element: (
          <RequireAuth
            component={JustSayinDetail}
            permissions={["read voice"]}
            requiresCompany
          />
        )
      },
      {
        path: ":voiceId/edit",
        element: (
          <RequireAuth
            component={JustSayinCreate}
            permissions={["update voice"]}
            requiresCompany
          />
        )
      }
    ]
  });
}

export default mainRoutes;
