import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import "./styles.scss";

interface DropdownContainerProps {
  alignment?: "right" | "left";
  full?: boolean;
  className?: string;
  children: (
    isOpen: boolean,
    setIsOpen: (isOpen: boolean) => void
  ) => React.ReactNode;
  optionContainer: (closeContainer: () => void) => React.ReactNode;
}

const DropdownContainer: React.FC<DropdownContainerProps> = ({
  alignment = "right",
  full,
  className,
  children,
  optionContainer
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const baseClass = "notme-dropdown-container";
  const rootClass = clsx(baseClass);
  const optionsClass = clsx(
    `${baseClass}--options`,
    alignment && alignment === "left" && `${baseClass}--options-left`,
    full && `${baseClass}--options-full`
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeContainer = () => {
    setIsOpen(false);
  };

  return (
    <div className={rootClass} ref={dropdownRef}>
      <div className={clsx(`${baseClass}--content`, className)}>
        {children(isOpen, setIsOpen)}
        {isOpen && (
          <div className={optionsClass}>{optionContainer(closeContainer)}</div>
        )}
      </div>
    </div>
  );
};

DropdownContainer.displayName = "DropdownContainer";

DropdownContainer.defaultProps = {
  alignment: "right",
  full: false,
  className: ""
};

DropdownContainer.propTypes = {
  alignment: PropTypes.oneOf(["left", "right"]),
  full: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.func.isRequired,
  optionContainer: PropTypes.func.isRequired
};

export default DropdownContainer;
