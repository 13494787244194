import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import "./styles.scss";

export interface AvatarProps {
  name: string;
  size?: string;
  id: number;
  context?: string;
  className?: string;
  otherProps?: Object;
}

const Avatar: React.FC<AvatarProps> = ({
  name,
  size = "44",
  id,
  context,
  className,
  ...otherProps
}) => {
  const baseClass = "notme-avatar";

  const [initials, setInitials] = useState("");
  const [fontSize, setFontSize] = useState(16);
  const [rootClass, setRootClass] = useState(baseClass);

  useEffect(() => {
    if (name && name !== "" && initials === "") {
      let initial = nameToInitial(name);
      setInitials(initial);
      let initialLength = lengthToSize(initial.length);
      setFontSize(initialLength);

      if (!context) {
        context = idToColor(id);
      }

      setRootClass(
        clsx(baseClass, context && `${baseClass}-${context}`, className)
      );
    }
  }, [name]);

  const nameToInitial = (name: string) => {
    let text = name.trim();
    let result = text;
    if (text.length > 5) {
      result = text
        .split(/\s/g)
        .map((n) => n[0])
        .join("")
        .slice(0, 4)
        .toUpperCase();
    }
    return result;
  };

  const lengthToSize = (initialLength: number) => {
    switch (initialLength) {
      case 1:
        return 18;
      case 2:
        return 16;
      case 3:
        return 14;
      case 4:
        return 10;
      default:
        return 10;
    }
  };

  const idToColor = (id: number) => {
    let lastDigits = id % 100;
    switch (true) {
      case lastDigits < 10:
        return "dark_red";
      case lastDigits < 20:
        return "dark_blue";
      case lastDigits < 30:
        return "red";
      case lastDigits < 40:
        return "dark_green";
      case lastDigits < 50:
        return "green";
      case lastDigits < 60:
        return "light_blue";
      case lastDigits < 70:
        return "blue";
      case lastDigits < 80:
        return "dark_grey";
      case lastDigits < 90:
        return "yellow";
      case lastDigits <= 100:
        return "light_red";
      default:
        return "light_red";
    }
  };

  return (
    <div
      className={rootClass}
      style={{
        width: size + "px",
        height: size + "px"
      }}
      {...otherProps}
    >
      <span className="notme-avatar-text" style={{ fontSize: fontSize }}>
        {initials}
      </span>
    </div>
  );
};

Avatar.displayName = "Avatar";

Avatar.defaultProps = {
  name: "",
  id: 0,
  context: "",
  className: ""
};

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.string,
  id: PropTypes.number.isRequired,
  context: PropTypes.string,
  className: PropTypes.string,
  otherProps: PropTypes.object
};

export default Avatar;
