import React from "react";
import "./styles.scss";

type LoaderProps = {
  style: React.CSSProperties;
};

const Loader: React.FC<LoaderProps> = ({ style }) => (
  <div className="notme-loader" style={style} />
);

export default Loader;
