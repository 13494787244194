import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import flashApi from "api/flashApi";
import userApi from "api/userApi";
import { notificationError } from "utils/custom_notifications";
import helpers from "api/helpers/helpers";
import CenterLayout from "layouts/CenterLayout";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import Tag from "components/ui-kit/Tag";
import dayjs from "dayjs";
import ThemeContext from "contexts/theme/ThemeContext";
import ImageLoading from "components/ui-kit/ImageLoading";
import Loader from "components/ui-kit/Loader";
import HeaderTitle from "components/HeaderTitle";
import { useAuth } from "contexts/auth/useAuth";
import permissions_utils from "utils/permissions_utils";
import Button from "components/ui-kit/Buttons/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faNewspaper,
  faMessage
} from "@fortawesome/free-solid-svg-icons";
import NewsfeedFile from "models/NewsfeedFile";
import Editor from "components/Editor";
import { useModal } from "react-hooks-use-modal";
import ActionModal from "components/ui-kit/Modals/ActionModal";
import EmptyData from "components/ui-kit/EmptyData";
import NewsfeedPost from "models/NewsfeedPost";
import Comment from "models/Comment";
import User from "models/User";
import { AxiosResponse } from "axios";
import { ReactionCount } from "models/app/ReactionCount";
import { aggregateReactions } from "utils/reaction_utils";
import ReactionItems from "components/Flash/ReactionItems";
import CommentInput from "components/Flash/CommentInput";
import CommentSection from "components/Flash/CommentSection";

const PostPage = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);
  const { postId } = useParams<{ postId?: string }>();
  const editorRef = useRef<any>(null);
  const [content, setContent] = useState<any>(null);

  const [post, setPost] = useState<NewsfeedPost | null>(null);
  const [files, setFiles] = useState<NewsfeedFile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [reactions, setReactions] = useState<ReactionCount[]>([]);
  const [users, setUsers] = useState<{
    [key: number]: User | null;
  }>([]);

  const [isPostDeleteLoading, setIsPostDeleteLoading] = useState(false);
  const [DeletePostModal, openDeletePostModal, closeDeletePostModal, isOpen] =
    useModal("root", {
      closeOnOverlayClick: true
    });

  const customBackNavigation = () => {
    // Check if location state exists and if it has 'fromEditOrNew' flag
    if (location.state?.fromEditOrNew) {
      navigate("/feed");
    } else {
      navigate(-1);
    }
  };

  const fetchUserDetails = async (userIds: string[]) => {
    setIsLoadingUsers(true);
    try {
      const newUserIds = userIds.filter(
        (userId: any) => !users[userId] && userId !== auth?.user?.id
      );
      if (newUserIds.length === 0) {
        return; // No new users to fetch
      }
      const userDetails = await Promise.all(
        newUserIds.map((userId) => userApi.getUserById(userId))
      );

      const newUsers = userDetails.reduce(
        (acc: any, user: AxiosResponse<User>) => {
          const userData = user.data;
          acc[userData.id] = userData;
          return acc;
        },
        {}
      );

      setUsers((prevUsers) => ({ ...prevUsers, ...newUsers }));
    } catch (_) {
    } finally {
      setIsLoadingUsers(false);
    }
  };

  useEffect(() => {
    const loadPost = async () => {
      try {
        setIsLoading(true);
        const [fetchedPost, fetchedFiles, commentsResult, reactionsResult] =
          await Promise.all([
            flashApi.getPostById(postId),
            flashApi.getPostFiles(postId),
            flashApi.getPostComments(postId),
            flashApi.getPostReactions(postId)
          ]);

        const fetchedComments = commentsResult.data?.data || [];
        const uniqueUserIds: string[] = Array.from(
          fetchedComments.reduce((acc: any, comment: Comment) => {
            if (comment.user_id != null) {
              acc.add(comment.user_id);
            }
            return acc;
          }, new Set<string>())
        );
        fetchUserDetails(uniqueUserIds);

        const fetchedReactions = reactionsResult.data?.data || [];

        // Use reduce to process reactions into the final array of aggregated reactions
        const aggregatedReactions = aggregateReactions(
          fetchedReactions,
          auth?.user!
        );

        setPost(fetchedPost.data);
        setContent(fetchedPost.data.content);
        setFiles(fetchedFiles.data.data);
        setComments(fetchedComments);
        setReactions(aggregatedReactions);

        // Check user_views_count and create a post view if necessary
        if (fetchedPost.data.user_views_count === 0) {
          await flashApi.createPostView(postId);
        }
      } catch (error) {
        notificationError(helpers.formatErrorMessage(error), dark);
      } finally {
        setIsLoading(false);
      }
    };

    if (postId) {
      loadPost();
    }
  }, [postId]);

  const deletePost = () => {
    setIsPostDeleteLoading(true);
    flashApi
      .deletePost(postId)
      .then(() => {
        notificationError(t("post_page.post_deleted"), dark);
        navigate("/feed", { replace: true });
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setIsPostDeleteLoading(false);
      });
  };

  const addReaction = async (name: any) => {
    try {
      await flashApi.createPostReaction(postId, { name });
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    }
  };

  const deleteReaction = async () => {
    try {
      await flashApi.deletePostReaction(postId);
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    }
  };

  const handleCommentDelete = async (commentId: number) => {
    try {
      await flashApi.deletePostComment(postId, commentId);
      setComments(comments.filter((comment) => comment.id !== commentId));
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    }
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const addLoggedInUserToState = async () => {
    if (auth?.user?.id && !users[auth?.user?.id]) {
      // Update the state by merging with the existing users
      setUsers((prevUsers) => ({
        ...prevUsers,
        [auth?.user?.id!]: auth.user!
      }));
    }
  };

  const handleCommentSubmit = async (comment: string) => {
    try {
      setIsAddingComment(true);
      const createdComment = await flashApi.createPostComment(postId, {
        comment
      });
      setComments([...comments, createdComment.data]);
    } catch (error) {
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setIsAddingComment(false);
    }
  };

  useEffect(() => {
    if (auth?.user) {
      addLoggedInUserToState();
    }
  }, [auth?.user]);

  const parsedDate = post ? dayjs(post.created).format("LLL") : "";

  return (
    <CenterLayout>
      <main className="post-page">
        <div className="post-page__nav">
          <HeaderTitle
            title={t(`post_page.post`)}
            canGoBack={true}
            customBackNavigation={customBackNavigation}
          />
          {!isLoading && post && (
            <div className="post-page__buttons">
              {(!post.default_post ||
                (post.default_post && can("create default posts"))) && (
                <>
                  {can("delete post") && (
                    <Button context="red" onClick={openDeletePostModal}>
                      {t("action_button.delete")}
                    </Button>
                  )}
                  {can("update post") && (
                    <Button
                      onClick={() => navigate(`/feed/posts/${postId}/edit`)}
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </Button>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {isLoading ? (
          <div className="post-page__loader-container">
            <Loader style={{ height: "30px", width: "50px" }} />
            <Loader style={{ height: "30px", width: "350px" }} />
            <Loader style={{ height: "30px", width: "150px" }} />
            <Loader style={{ height: "300px", width: "100%" }} />
            <Loader style={{ height: "30px", width: "100%" }} />
            <Loader style={{ height: "30px", width: "400px" }} />
            <Loader style={{ height: "30px", width: "100%" }} />
          </div>
        ) : post ? (
          <>
            <div className="post-page__header">
              <div className="post-page__header-tag-container">
                <ReactionItems
                  initialReactions={reactions}
                  addReaction={addReaction}
                  deleteReaction={deleteReaction}
                />
                <Tag round className="post-page__header-tag">
                  {post.type_name}
                </Tag>
                {!post.published && (
                  <Tag round subtle>
                    {t("post_page.draft")}
                  </Tag>
                )}
              </div>
              <div className="post-page__header-title">{post.title}</div>
              <div className="post-page__header-info">
                <span>
                  {t("post_page.published_on")} {parsedDate}
                </span>
                {can("update post") &&
                post.views_count &&
                post.views_count > 0 ? (
                  <span>{` • ${post.views_count} ${t(
                    "post_page.views"
                  )}`}</span>
                ) : null}
              </div>
              <div className="post-page__header-thumbnail">
                <ImageLoading
                  src={post.thumbnail_url}
                  alt={post.title}
                  loading={isLoading}
                />
              </div>
            </div>
            <div className="post-page__content">
              <div className="post-page__content-body">
                <Editor
                  content={content}
                  postId={Number(postId)}
                  files={files}
                  onChange={(newContent) => setContent(newContent)}
                  editable={false}
                />
              </div>
            </div>

            <div className="post-page__comments-container">
              <CommentSection
                comments={comments}
                isLoading={isLoading}
                isAddingComment={isAddingComment}
                isLoadingUsers={isLoadingUsers}
                users={users}
                authUser={auth?.user!}
                userKey="created_by"
                onDelete={handleCommentDelete}
              />
            </div>
          </>
        ) : (
          <EmptyData
            title={t("feed.no_post_found")}
            description={t("feed.no_post_description")}
            icon={faNewspaper}
          />
        )}
        <CommentInput onSubmit={handleCommentSubmit} />
        <ActionModal
          Modal={DeletePostModal}
          close={closeDeletePostModal}
          title={t("post_page.delete_post")}
          description={t("post_page.delete_post_description")}
        >
          <Button
            context="red"
            onClick={deletePost}
            loading={isPostDeleteLoading}
          >
            {t("action_button.delete")}
          </Button>
        </ActionModal>
      </main>
    </CenterLayout>
  );
};

export default PostPage;
