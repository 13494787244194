import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import "./styles.scss";
import SplitLayout from "layouts/SplitLayout";

import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import Button from "components/ui-kit/Buttons/Button";
import Input, { OptionType } from "components/ui-kit/Input";
import Switch from "components/ui-kit/Switch";
import Editor from "components/Editor";
import HeaderTitle from "components/HeaderTitle";
import flashApi from "api/flashApi";
import {
  notificationError,
  notificationSuccess
} from "utils/custom_notifications";
import ThemeContext from "contexts/theme/ThemeContext";
import helpers from "api/helpers/helpers";
import {
  useNavigate,
  useParams,
  useLocation,
  unstable_usePrompt as usePrompt
} from "react-router-dom";
import { useAuth } from "contexts/auth/useAuth";
import { useEntity } from "contexts/entity/useEntity";
import Loader from "components/ui-kit/Loader";
import NewsfeedFile from "models/NewsfeedFile";
import DocumentDropzone from "components/DocumentDropzone";
import PostActionModal, { PostActionModalType } from "./PostActionModal";
import { useModal } from "react-hooks-use-modal";
import permissions_utils from "utils/permissions_utils";
import EntitySelectorDropdown from "components/EntitySelector/EntitySelectorDropdown";
import { EntityType } from "models/app/EntityType";

const NewsfeedPostPage = () => {
  const { postId } = useParams();
  const { t } = useTranslation();
  const { dark } = useContext(ThemeContext);
  const auth = useAuth();
  const entities = useEntity();
  const location = useLocation();
  const user = auth?.user;
  const companyId = user?.company?.id;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [postType, setPostType] = useState<string>("article");
  const [isFeaturedIn, setIsFeaturedIn] = useState<boolean>(false);
  const [isDefaultPost, setIsDefaultPost] = useState<boolean>(false);
  const [isCommunityPost, setIsCommunityPost] = useState<boolean>(false);
  const [postTypes, setPostTypes] = useState<OptionType[]>([]);
  const [postInfo, setPostInfo] = useState([
    {
      title: t("post_page.status"),
      value: "Created"
    },
    { title: t("post_page.created_on"), value: dayjs(Date()).format("lll") }
  ]);
  const [title, setTitle] = useState("");
  const [coverImage, setCoverImage] = useState<File | string | null>(null);
  const [content, setContent] = useState<any>(null);
  const [resetContentKey, setResetContentKey] = useState<number>(0);
  const [isPublished, setIsPublished] = useState<boolean>(false);
  const [hasAddedNewImage, setHasAddedNewImage] = useState<boolean>(false);
  const [initialPostData, setInitialPostData] = useState<any>(null);
  const [companies, setCompanies] = useState<OptionType[]>([]);
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([
    companyId ?? 0
  ]);
  const editorRef = useRef<any>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [files, setFiles] = useState<NewsfeedFile[]>([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

  const [modalActionType, setModalActionType] = useState<PostActionModalType>(
    PostActionModalType.DRAFT
  );

  const entitiesSelected = useMemo(() => {
    return entities?.entitiesList?.filter((entity) => {
      return (
        selectedCompanies.findIndex(
          (selectedCompany) => selectedCompany === entity.id
        ) !== -1
      );
    });
  }, [selectedCompanies]);

  const [ActionModalContainer, openActionModal, closeActionModal, isOpen] =
    useModal("root", {
      closeOnOverlayClick: true
    });

  const isNewArticle = location.pathname.split("/").pop() === "new";

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const handleChanges = () => {
    if (!hasUnsavedChanges) {
      setHasUnsavedChanges(true);
    }
  };

  // memoized enableSave value
  const enableSave = useMemo(() => {
    return (
      title &&
      coverImage &&
      content?.content?.length > 0 &&
      !isLoading &&
      !actionLoading
    );
  }, [title, content, coverImage]);

  const handleFileImport = (file: File | null) => {
    setHasAddedNewImage(true);
    setCoverImage(file);
    handleChanges();
  };

  const cancelPost = () => {
    setActionLoading(true);
    flashApi
      .deletePost(postId)
      .then((response) => {
        notificationError(t("post_page.post_deleted"), dark);
        navigate("/feed");
      })
      .catch((err) => {
        notificationError(helpers.formatErrorMessage(err), dark);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const updatePostType = (e: any) => {
    let language_selected = postTypes[e.target.options.selectedIndex];
    setPostType(language_selected.value);
    handleChanges();
  };

  const updateEntitySelected = (
    entitySelected: EntityType,
    shouldAdd: boolean
  ) => {
    if (shouldAdd) {
      setSelectedCompanies((prev) => [...prev, entitySelected.id!]);
    } else {
      setSelectedCompanies((prev) =>
        prev.filter((company) => company !== entitySelected.id)
      );
    }
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(e.target.value);
    handleChanges();
  };

  // This function compares the initial and current post data and returns an object with only the fields that have changed.
  const getChangedFields = (initialData: any, currentData: any) => {
    const changedFields: any = {};
    for (const key of Object.keys(currentData)) {
      if (initialData[key] !== currentData[key] && key !== "thumbnail_url") {
        changedFields[key] = currentData[key];
      }
    }

    if (hasAddedNewImage) {
      changedFields["thumbnail_url"] = coverImage;
    }

    return changedFields;
  };

  const handlePublish = async (published: boolean) => {
    setActionLoading(true);
    setHasUnsavedChanges(false);
    try {
      const postData = {
        title: title,
        company_ids: [user?.company?.id],
        published: published,
        type: postType,
        featured: isFeaturedIn,
        created_by: user?.id,
        content: content,
        thumbnail_url: coverImage,
        default_post: false
      };

      // Check permissions and modify postData accordingly
      if (can("create default posts") && isDefaultPost) {
        postData.default_post = true;
      }

      if (can("create community posts")) {
        if (isCommunityPost) {
          postData.company_ids = [0];
        } else if (selectedCompanies) {
          postData.company_ids = selectedCompanies;
        }
      }

      let res;
      if (postId) {
        // If postId is present, we're updating an existing post.
        res = await flashApi.updatePost(
          postId,
          getChangedFields(initialPostData, postData)
        );
      } else {
        // If postId is not present, we're creating a new post.
        res = await flashApi.createPost(postData);
      }

      notificationSuccess(
        t(postId ? "post_page.post_updated" : "post_page.post_created"),
        dark
      );
      const { data } = res;
      if (data.id) {
        navigate(`/feed/posts/${data.id}`, {
          state: { fromEditOrNew: true }
        });
      }
    } catch (error) {
      handleChanges();
      notificationError(helpers.formatErrorMessage(error), dark);
    } finally {
      setActionLoading(false);
    }
  };

  const handleKeyDown = (event: any) => {
    // Check if key pressed is Enter or Tab
    if (event.keyCode === 13 || event.keyCode === 9) {
      // Prevent default action
      event.preventDefault();
      // Focus on the editor
      if (editorRef && editorRef.current) {
        (editorRef.current as any).view.focus();
      }
    }
  };

  usePrompt({
    message: t("post_page.unsaved_changes"),
    when: hasUnsavedChanges === true
  });

  // Resize the textarea when the title changes.
  useEffect(() => {
    if (textAreaRef && textAreaRef.current) {
      // Reset the height so it can shrink to fit the content.
      textAreaRef.current.style.height = "inherit";
      // Set the height to match the content.
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [title]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        event.returnValue = true; // This is required to display the confirmation message
      }
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  // Fetch the post when the component is mounted.
  useEffect(() => {
    const loadPostAndTypes = async () => {
      try {
        setIsLoading(true);

        // Fetch the post types, post data and post files in parallel.
        const [postTypesRes, fetchedPost, fetchedFiles] = await Promise.all([
          flashApi.getAllPostTypes(),
          flashApi.getPostById(postId),
          flashApi.getPostFiles(postId)
        ]);

        if (postTypesRes.data) {
          const postTypesData = postTypesRes.data.map(
            (type: [string, string]) => ({
              key: type[0],
              value: type[0],
              title: type[1]
            })
          );
          setPostTypes(postTypesData);
        }

        if (fetchedPost.data) {
          setInitialPostData(fetchedPost.data);
          setPostType(fetchedPost.data.type);
          setIsFeaturedIn(fetchedPost.data.featured);
          setTitle(fetchedPost.data.title);
          setContent(fetchedPost.data.content);
          setIsPublished(fetchedPost.data.published);
          setIsDefaultPost(fetchedPost.data.default_post);
          if (fetchedPost.data.company_ids[0] === 0) {
            setIsCommunityPost(true);
          } else {
            setSelectedCompanies(fetchedPost.data.company_ids);
          }
          const createdDate = new Date(fetchedPost.data.created);
          const formattedCreatedDate = dayjs(createdDate).format("lll");
          setPostInfo([
            {
              title: t("post_page.status"),
              value: setupStatus(fetchedPost.data.published)
            },
            { title: t("post_page.created_on"), value: formattedCreatedDate }
          ]);

          if (fetchedPost.data.thumbnail_url) {
            setCoverImage(fetchedPost.data.thumbnail_url);
          }
        }

        if (fetchedFiles.data) {
          setFiles(fetchedFiles.data.data);
        }

        setResetContentKey((prevKey) => prevKey + 1); // Increment resetContentKey to reset the editor.
      } catch (error) {
        notificationError(helpers.formatErrorMessage(error), dark);
      } finally {
        setIsLoading(false);
      }
    };

    if (postId) {
      loadPostAndTypes();
    }
  }, [postId]);

  const setupStatus = (published: boolean) => {
    return published ? t("post_page.published") : t("post_page.draft");
  };

  const modalButtonAction = () => {
    switch (modalActionType) {
      case PostActionModalType.DRAFT:
        handlePublish(false);
        break;
      case PostActionModalType.PUBLISH:
        handlePublish(true);
        break;
      case PostActionModalType.CANCEL:
        cancelPost();
        break;
      default:
        break;
    }
  };

  const openActionModalType = (type: PostActionModalType) => {
    setModalActionType(type);
    openActionModal();
  };

  useEffect(() => {
    if (entities?.entitiesList && entities.entitiesList.length > 0) {
      const companiesData = entities.entitiesList.map(
        (company): OptionType => ({
          key: company.id?.toString(),
          value: company.id?.toString(),
          title: company.name
        })
      );
      setCompanies(companiesData);
    }
  }, [entities?.entitiesList]);

  useEffect(() => {
    if (actionLoading === false) {
      closeActionModal();
    }
  }, [actionLoading]);

  return (
    <>
      <SplitLayout
        switchOrderOnMobile={true}
        headerComponent={
          <div className="newsfeed-header">
            <div className="newsfeed-header__left">
              <HeaderTitle
                title={
                  isNewArticle
                    ? t("post_page.new_post")
                    : t("post_page.edit_post")
                }
                canGoBack={isNewArticle ? false : true}
              />
            </div>
            <div className="newsfeed-header__right">
              <Button
                context="light_blue"
                outline
                disabled={!enableSave}
                onClick={() => {
                  openActionModalType(PostActionModalType.DRAFT);
                }}
              >
                {t("post_page.save_as_draft")}
              </Button>
              <Button
                disabled={!enableSave}
                onClick={() => {
                  openActionModalType(PostActionModalType.PUBLISH);
                }}
              >
                {t("action_button.publish")}
              </Button>
              {isNewArticle && (
                <Button
                  context="red"
                  onClick={() => {
                    openActionModalType(PostActionModalType.CANCEL);
                  }}
                >
                  {t("action_button.cancel")}
                </Button>
              )}
            </div>
          </div>
        }
        leftComponent={
          <>
            {isLoading ? (
              <div className="newsfeed-loader">
                <Loader style={{ height: "30px", width: "120px" }} />
                <Loader style={{ height: "40px", width: "400px" }} />
                <Loader style={{ height: "20px", width: "280px" }} />
                <Loader style={{ height: "20px", width: "300px" }} />
                <Loader style={{ height: "20px", width: "240px" }} />
                <Loader style={{ height: "40px", width: "180px" }} />
                <Loader style={{ height: "20px", width: "280px" }} />
                <Loader style={{ height: "20px", width: "300px" }} />
                <Loader style={{ height: "40px", width: "240px" }} />
                <Loader style={{ height: "20px", width: "480px" }} />
              </div>
            ) : (
              <>
                <textarea
                  ref={textAreaRef}
                  value={title}
                  className="newsfeed-title-input"
                  placeholder={t("post_page.title") + "*"}
                  onChange={handleTitleChange}
                  onKeyDown={handleKeyDown}
                  rows={1}
                />
                <Editor
                  key={resetContentKey}
                  content={content}
                  postId={Number(postId)}
                  files={files}
                  onChange={(newContent) =>
                    setTimeout(() => {
                      setContent(newContent);
                      handleChanges();
                    })
                  }
                  onChangeEditorRef={(editor: any) => {
                    editorRef.current = editor;
                  }}
                />
              </>
            )}
          </>
        }
        rightComponent={
          <>
            {isLoading ? (
              <div className="newsfeed-loader">
                <Loader style={{ height: "32px", width: "120px" }} />
                <Loader style={{ height: "48px", width: "100%" }} />
                <Loader style={{ height: "70px", width: "100%" }} />
                <Loader style={{ height: "200px", width: "100%" }} />
                <Loader style={{ height: "28px", width: "100%" }} />
              </div>
            ) : (
              <>
                {can("create default posts") ||
                can("create community posts") ? (
                  <div className="newsfeed-info__admin">
                    <h5 className="header-section-title">
                      {t("post_page.administration")}
                    </h5>
                    {can("create default posts") && (
                      <div className="newsfeed-info__admin-item">
                        <p>{t("post_page.mark_as_default_post")}</p>
                        <Switch
                          checked={isDefaultPost}
                          onClick={() => setIsDefaultPost(!isDefaultPost)}
                        />
                      </div>
                    )}
                    {can("create community posts") && (
                      <div className="newsfeed-info__admin-item">
                        <p>{t("post_page.mark_as_community_post")}</p>
                        <Switch
                          checked={isCommunityPost}
                          onClick={() => setIsCommunityPost(!isCommunityPost)}
                        />
                      </div>
                    )}
                    {!isCommunityPost && (
                      <EntitySelectorDropdown
                        entities={entities?.entitiesList!}
                        entitiesSelected={entitiesSelected}
                        updateEntitySelected={updateEntitySelected}
                        isOpen={true}
                        isMobile={false}
                        selectedFirst
                      />
                    )}
                  </div>
                ) : null}

                <h5 className="header-section-title">
                  {t("post_page.information")}
                </h5>
                <Input
                  label={t("type")}
                  inputType="select"
                  value={postType}
                  options={postTypes}
                  onChange={updatePostType}
                />
                <ul className="newsfeed-info-list">
                  {postInfo.map((item) => (
                    <li className="newsfeed-info-item" key={item.title}>
                      <p>
                        <span>{item.title}:</span> {item.value}
                      </p>
                    </li>
                  ))}
                </ul>
                <div className="newsfeed-info-featured">
                  <p>{t("post_page.mark_as_featured")}</p>
                  <Switch
                    checked={isFeaturedIn}
                    onClick={() => setIsFeaturedIn(!isFeaturedIn)}
                  />
                </div>
                <div className="newsfeed-info-cover">
                  <h5 className="header-section-title">
                    {t("post_page.cover_image")} *
                  </h5>
                  {coverImage ? (
                    <>
                      <img
                        className="newsfeed-info-cover-image"
                        src={
                          hasAddedNewImage
                            ? URL.createObjectURL(coverImage as File)
                            : (coverImage as string)
                        }
                      />
                      <Button context="red" onClick={() => setCoverImage(null)}>
                        {t("action_button.delete")}
                      </Button>
                    </>
                  ) : (
                    <DocumentDropzone
                      onFileAdded={handleFileImport}
                      maxFileSize={20}
                      isImage={true}
                    />
                  )}
                </div>
              </>
            )}
          </>
        }
      />

      <PostActionModal
        Modal={ActionModalContainer}
        type={modalActionType}
        actionLoading={actionLoading}
        actionButton={modalButtonAction}
        close={closeActionModal}
      />
    </>
  );
};

export default NewsfeedPostPage;
