import Auth from "contexts/auth/useAuth";

const can = (permission: string = "", auth: Auth | null) => {
  let role = auth?.user?.role;
  let permissions = auth?.permissions;
  if (permission !== "") {
    if (role) {
      if (role === "super admin") {
        return true;
      }
      if (permissions) {
        if (permissions.includes(permission)) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
  return false;
};

const hasCompany = (auth: Auth | null) => {
  return auth?.user?.company !== null && auth?.user?.company?.id;
};

const permissions_utils = {
  can,
  hasCompany
};

export default permissions_utils;
