import React, { useContext, useMemo } from "react";
import "./styles.scss";
import clsx from "clsx";
import { EntityType } from "models/app/EntityType";

import { useAuth } from "contexts/auth/useAuth";
import { useEntity } from "contexts/entity/useEntity";
import ThemeContext from "contexts/theme/ThemeContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";
import useDropdownMenu from "react-accessible-dropdown-menu-hook";
import permissions_utils from "utils/permissions_utils";
import organizationUtils from "utils/organization_utils";

import EntityLogo from "components/EntityLogo";
import EntitySelectTag from "./EntitySelectTag";
import EntitySelectorDropdown from "./EntitySelectorDropdown";
import EntityLogoCollapsed from "components/EntityLogoCollapsed";

import logo_dark from "../../assets/img/logo_dark.png";

const EntitySelector: React.FC = () => {
  const auth = useAuth();
  const entity = useEntity();

  const { sidebarExpanded } = useContext(ThemeContext);
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { isOpen, setIsOpen } = useDropdownMenu(1);
  const fallbackCompany = {
    name: "#NotMe",
    logo: logo_dark,
    id: 0
  };

  const can = (permission: string) => {
    return permissions_utils.can(permission, auth);
  };

  const canShowSelector = useMemo(() => {
    return entity?.hasSubEntities() && can("read any post");
  }, [entity, auth]);

  const updateEntitySelected = (
    entitySelected: EntityType,
    shouldAdd: boolean
  ) => {
    if (shouldAdd) {
      entity?.addToEntitiesSelected(entitySelected);
    } else {
      entity?.removeFromEntitiesSelected(entitySelected);
    }
  };

  const updateEntitySelectorIsOpen = () => {
    if (canShowSelector) {
      setIsOpen(!isOpen);
    }
  };

  // Logic to add OpenPlatformEntity if the user has the right permission
  const entities = useMemo(() => {
    let entitiesList = entity?.entitiesList || [];
    if (can("create community posts")) {
      entitiesList = [
        organizationUtils.getOpenPlatformEntity() as EntityType,
        ...entitiesList
      ];
    }
    return entitiesList;
  }, [entity?.entitiesList, can]);

  return (
    <>
      {sidebarExpanded || isMobile ? (
        <div
          className={clsx(
            "entity-selector",
            canShowSelector ? "entity-selector-user-interaction" : null
          )}
          onClick={() => updateEntitySelectorIsOpen()}
        >
          <div className="entity-selector-content">
            {entity?.hasStrictlyEntitiesSelected() ? (
              <div className="entity-selector-tag-container">
                {entity.getEntitiesSelected().map((company) => (
                  <EntitySelectTag
                    company={company}
                    key={company.id}
                    onDelete={() => updateEntitySelected(company, false)}
                  />
                ))}
              </div>
            ) : (
              <div className="entity-selector-container">
                <EntityLogo
                  company={auth?.user?.company ?? fallbackCompany}
                  sub_company={
                    entity && entity.hasEntitiesSelected()
                      ? entity?.getEntitiesSelected()[0]
                      : undefined
                  }
                />
                <div className="entity-selector-info">
                  <p className="entity-selector-info-entity">
                    {entity && entity.hasEntitiesSelected()
                      ? entity?.getEntitiesSelected()[0].name
                      : auth?.user?.company?.name ?? fallbackCompany.name}
                  </p>
                  {auth?.user?.job_title && (
                    <p className="entity-selector-info-role">
                      {auth?.user?.job_title}
                    </p>
                  )}
                </div>
              </div>
            )}
            {canShowSelector ? (
              <div className="entity-selector-button">
                <FontAwesomeIcon icon={faCaretUp} />
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className="entity-selector-button-second-icon"
                />
              </div>
            ) : null}
          </div>
          {entity && entity.hasEntitiesSelected() ? (
            <div
              className="entity-selector-clear-button"
              onClick={(e) => {
                e.stopPropagation();
                entity?.clearEntitiesSelected();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          ) : null}
        </div>
      ) : (
        <div
          className={clsx(
            "entity-selector-reduced",
            canShowSelector ? "entity-selector-reduced-user-interaction" : null
          )}
          onClick={() => updateEntitySelectorIsOpen()}
        >
          <EntityLogoCollapsed
            entity={auth?.user?.company}
            nb_entities={entity?.getEntitiesSelected().length || 0}
            clearEntitiesSelected={(e) => {
              e.stopPropagation();
              entity?.clearEntitiesSelected();
            }}
          />
        </div>
      )}
      {canShowSelector ? (
        <EntitySelectorDropdown
          entities={entities!}
          entitiesSelected={entity?.entitiesSelected}
          updateEntitySelected={updateEntitySelected}
          isOpen={isOpen}
          isMobile={isMobile}
        />
      ) : null}
    </>
  );
};

export default EntitySelector;
