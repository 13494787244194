import userApi from "api/userApi";
import LoginData from "models/app/LoginData";
import SignUpData from "models/app/SignUpData";
import User from "models/User";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import url_utils from "utils/url_utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { notificationError } from "utils/custom_notifications";
import { useTranslation } from "react-i18next";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";

interface Auth {
  token: string;
  user?: User | null;
  permissions?: string[] | null;
  isLoggedIn: Boolean;
  isPhoneVerified: Boolean;
  login: (userDetails: LoginData) => Promise<any>;
  signUp: (userDetails: SignUpData) => Promise<any>;
  logout: () => void;
  getUser: () => Promise<any>;
}

const authContext = createContext<Auth | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { t, i18n } = useTranslation();

  const tokenName = url_utils.getTokenName();
  const cookieDomain = url_utils.getDomainUrl();

  const setTokenCookies = (token: string) => {
    // Get token expiration date
    const tokenDecoded: any = jwtDecode(token);
    const tokenExpiracyDate = dayjs.unix(tokenDecoded.exp);
    const tokenDurationDays = tokenExpiracyDate.diff(dayjs(), "days");

    // Set new token in Cookies
    Cookies.set(tokenName, token, {
      domain: cookieDomain,
      secure: true,
      expires: tokenDurationDays
    });
  };


  // To be removed
  const getInitialToken = () => {
    const cookiesToken = Cookies.get(tokenName);
    const urlToken = url_utils.getUrlToken();

    if (cookiesToken) {
      // Verify the token in the cookies
      return cookiesToken;

    } else if (urlToken) {
      // Get the token from an url parameter
      setTokenCookies(urlToken);
      return urlToken;

    } else {
      return "";
    }
  };
  const initialToken = getInitialToken();

  const initialUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || "")
    : null;

  const initialPermissions: string[] = localStorage.getItem("permissions")
    ? JSON.parse(localStorage.getItem("permissions") || "")
    : null;

  const [token, setToken] = useState(initialToken);
  const [user, setUser] = useState<User | null>(initialUser);
  const [permissions, setPermissions] = useState<string[] | null>(
    initialPermissions
  );
  const isUserLoggedIn = !!token;
  const isPhoneVerified = user?.phone_verified || false;

  const navigate = useNavigate();

  useEffect(() => {
    const retrieveUser = async () => {
      await getUser();
      await getPermissions();
    };
    if (token) {
      retrieveUser().catch(() => {
        notificationError(t("user_disconnected_message"), false);
        logout();
      });
    }
  }, []);

  const login = async (userDetails: any): Promise<any> => {
    return new Promise((resolve, reject) => {
      userApi
        .loginUser(userDetails)
        .then((response: any) => {
          let response_data = response.data;
          setTokenCookies(response_data.token);
          setToken(response_data.token);
          setUser(response_data.user);
          localStorage.setItem("user", JSON.stringify(response_data.user));
          if (response_data.user?.locale) {
            i18n.changeLanguage(response_data.user?.locale);
          }
          getPermissions();
          resolve(response_data);
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  const signUp = async (userDetails: SignUpData): Promise<any> => {
    return new Promise((resolve, reject) => {
      userApi
        .signUp(userDetails)
        .then((response: any) => {
          if (response.status === 200) {
            let response_data = response.data;
            resolve(response_data);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getUser = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      userApi
        .getUser()
        .then((response: any) => {
          if (response.status === 200) {
            let response_data = response.data;
            setUser(response_data);
            localStorage.setItem("user", JSON.stringify(response_data));
            if (response_data.locale) {
              i18n.changeLanguage(response_data.locale);
            }
            getPermissions();
            resolve(response_data);
          }
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  const getPermissions = async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      userApi
        .getPermissions()
        .then((response: any) => {
          if (response.status === 200) {
            let response_data = response.data;
            setPermissions(response_data);
            localStorage.setItem("permissions", JSON.stringify(response_data));
            resolve(response_data);
          }
        })
        .catch((err: any) => {
          reject(err);
        });
    });
  };

  const logout = () => {
    setToken("");
    setUser(null);
    setPermissions(null);
    Cookies.remove(tokenName, {
      domain: cookieDomain
    });
    localStorage.removeItem("user");
    localStorage.removeItem("permissions");
    navigate("/login", { replace: true });
  };

  const contextValue = {
    token: token,
    user: user,
    permissions: permissions,
    isLoggedIn: isUserLoggedIn,
    isPhoneVerified: isPhoneVerified,
    login: login,
    signUp: signUp,
    logout: logout,
    getUser: getUser
  };

  const authContextProvider = () => {
    return (
      <authContext.Provider value={contextValue}>
        {children}
      </authContext.Provider>
    );
  };

  const loadingUser = () => {
    const styles: React.CSSProperties = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      position: "fixed",
      top: 0,
      left: 0,
      color: "var(--grey)"
    };

    return (
      <div style={styles}>
        <FontAwesomeIcon icon={faCircleNotch} spin size="2x" />
      </div>
    );
  };

  if (isUserLoggedIn) {
    if (user) {
      return authContextProvider();
    }
    return loadingUser();
  }
  return authContextProvider();
}

export const useAuth = () => {
  return useContext(authContext);
};

export default Auth;
